<template>
    <div class="clearfix opacity-box">
        <input class="opacity-input fr" :disabled="disabled" :max="max" :min="min" :step="step" :style="{ width: width + 'px' }" type="number" @change="change" @input="onInput" @mousedown.stop="onMouseDown" :value="modelValue" />
        <span class="opacity-percent">{{ unit }}</span>
    </div>
</template>
<script>
export default {
    props: {
        width: { type: Number, default: 120 },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100000 },
        unit: { type: String, default: '%' },
        step: { type: String, default: '1' },
        disabled: { type: Boolean, default: false },
        modelValue: { type: Number, default: 0, require: true },
        onChange: { type: Function }
    },
    emits: ['update:modelValue', 'onChange'],
    methods: {
        onInput(event) {
            var max = parseFloat(this.max)
            var min = parseFloat(this.min)
            var val = event.target.value
            if (val > max) {
                val = max
            } else if (val < min) {
                val = min
            }
            this.$emit('update:modelValue', Math.round(val * 10) / 10)
        },
        onMouseDown(event) {
            var max = parseFloat(this.max)
            var min = parseFloat(this.min)
            var initX = event.pageX
            var initValue = Number(event.target.value)
            var step = this.step
            var _this = this
            var mousemove = function mousemove(e) {
                var moveX = (e.pageX - initX) / ((100 * 1) / max)
                var val = void 0
                if (step) {
                    val = Number(Number(initValue + moveX * step).toFixed(1))
                } else {
                    val = Number(initValue + moveX * 1)
                }
                if (val > max) {
                    val = max
                } else if (val < min) {
                    val = min
                }
                _this.$emit('update:modelValue', Math.round(val * 10) / 10)
            }
            var mouseup = function mouseup(event) {
                _this.$emit("onChange", event.target.value)
                document.removeEventListener('mousemove', mousemove)
                document.removeEventListener('mouseup', mouseup)
            }

            document.addEventListener('mousemove', mousemove)
            document.addEventListener('mouseup', mouseup)
        }
    }
}
</script>