import * as VueRouter from 'vue-router'
import store from '@/store'
import * as Vue from 'vue'
import i18n from '@/lang'
import AvueRouter from './avue-router'

const constantRouterMap = [
    {
        path: '/p/:info',
        name: 'editorScene',
        component: () => import('@/page/index.vue'),
    },
    {
        path: "/v/:info",
        name: 'view',
        component: () => import('@/page/view/index.vue'),
        hidden: true,
    }
];
let Router = VueRouter.createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (from.meta.keepAlive) {
                from.meta.savedPosition = document.body.scrollTop
            }
            return { left: 0, top: to.meta.savedPosition || 0 }
        }
    },
    routes: constantRouterMap,
    history: VueRouter.createWebHistory(),
})
AvueRouter.install(Vue, Router, store, i18n)
export default Router
