/**
 * 全局配置
 */
export default {
    title: 'LINSN-云发布系统',
    key: 'linsn',
    host: 'wss://www.linsncloud.com',
    clientId: 'saber', // 客户端id
    clientSecret: 'saber_secret', // 客户端密钥
    tenantMode: !0,
    //http的status默认放行不才用统一处理的,
    statusWhiteList: [],
    AppId_MP: 'wx2bc7e248bd51a6da',
    //配置首页不可关闭
    isFirstPage: false,
    programInfo: {
        createUser: '1',
        createTime: '2022-04-09 16:56:08',
        updateUser: -1,
        updateTime: '',
        status: 0,
        isDeleted: 0,
        id: 1649494567,
        programinfoName: '节目-2022040992878',
        programinfoType: 1,
        programinfoDuration: 3,
        programinfoDescription: '',
        programinfoGroup: -1,
        resolution: '1920*1080',
        auditRemark: -1,
        auditStatus: 0,
        auditTime: -1,
        auditUser: -1,
        coverUrl: '',
        resolutions: [{
            "id": 3,
            "setCodeId": 1,
            "setCodeValue": "520*520",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        {
            "id": 4,
            "setCodeId": 1,
            "setCodeValue": "550*550",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        {
            "id": 7,
            "setCodeId": 1,
            "setCodeValue": "1980*1080",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        {
            "id": 281,
            "setCodeId": 1,
            "setCodeValue": "512*512",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        {
            "id": 472,
            "setCodeId": 1,
            "setCodeValue": "768*512",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        {
            "id": 473,
            "setCodeId": 1,
            "setCodeValue": "1920*1080",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        {
            "id": 887,
            "setCodeId": 1,
            "setCodeValue": "2560*128",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        {
            "id": 88,
            "setCodeId": 1,
            "setCodeValue": "512*860",
            "createUser": 1,
            "createTime": "",
            "isDeleted": 0
        },
        ],
    },
    dataList: [
        {
            id: 2361,
            key: 1,
            name: '节目页1',
            duration: 3,
            order: 1,
            maxIndex: 0,
            playNum: 1,
            groups: [
                {
                    "id": "group:8832660996",
                    "name": "组合1",
                    "compIds": [
                        3756985956,
                        3292224639
                    ]
                }
            ],
            elements: [
                {
                    id: 65672,
                    name: '文本1',
                    elementId: '9470832309',
                    num: 1,
                    type: 'text',
                    visible: true,
                    lock: false,
                    css: {
                        top: 357,
                        left: 237,
                        width: 320,
                        height: 80,
                        writingMode: 'horizontal-tb',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        lineHeight: 1.5,
                        fontSize: 14,
                        color: 'rgba(242,166,83,1)',
                        zIndex: 1,
                        backgroundColor: 'rgba(0, 0, 0, 1);',
                        opacity: 1,
                        transform: 'rotateZ(0deg)',
                        borderWidth: 0,
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,1)',
                        borderRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                    },
                    properties: {
                        content: '灵芯宇开发技术有限公司',
                        startTime: 2,
                        endTime: 6,
                        stay: 3,
                    },
                    anim: {
                        outAnim: 9999,
                        inAnim: 9999,
                        speedIn: 5,
                        speedOut: 0,
                    }
                },
                {
                    id: 65674,
                    name: '日期1',
                    elementId: '6226334296',
                    num: 3,
                    type: 'digitalClock',
                    visible: true,
                    lock: false,
                    css: {
                        top: 147,
                        left: 326,
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        width: 375,
                        height: 40,
                        color: 'rgb(255, 255, 255)',
                        fontSize: 24,
                        zIndex: 3,
                        backgroundColor: 'rgba(0, 0, 0, 1);',
                        opacity: 1,
                        transform: 'rotateZ(0deg)',
                        borderWidth: 0,
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,1)',
                        borderRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                    },
                    properties: {
                        startTime: 1,
                        endTime: 6,
                        stay: 5,
                        timeZone: 'Asia/Shanghai',
                        date: true,
                        dateFormat: 1,
                        dateStyle: 1,
                        week: true,
                        time: true,
                        timeShowStyle: 1,
                        timeStyle: 1,
                        timeWay: 1,
                    },
                    anim: {
                        outAnim: 9999,
                        inAnim: 9999,
                        speedIn: 1,
                        speedOut: 0,
                    }
                },
                {
                    id: 5151566,
                    type: "timing",
                    name: "计时1",
                    elementId: 4383647432,
                    num: 1,
                    lock: false,
                    visible: true,
                    css: {
                        top: 246,
                        left: 1116,
                        width: 310,
                        height: 60,
                        fontSize: 20,
                        color: "#ffffff",
                        fontFamily: "Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif",
                        whiteSpace: "nowrap",
                        fontWeight: "normal",
                        fontStyle: "normal",
                        textDecoration: "none",
                        textAlign: "center",
                        verticalAlign: "middle",
                        lineHeight: 0,
                        letterSpacing: 0,
                        opacity: 0,
                        borderStyle: "none",
                        borderColor: "",
                        borderWidth: 0,
                        borderRadius: 0,
                        zIndex: 7
                    },
                    properties: {
                        startTime: 0,
                        endTime: 5,
                        stay: 5,
                        startCountNum: 1,
                        endCountNum: 1,
                        start_count: new Date().getTime(),
                        end_count: new Date().getTime(),
                        mode: [true, true, true, true],
                        timingType: 'downTime',
                        downTimeConstent: '',
                        upTimeConstent: '',
                        upTimeDisplayText: 'absolute',
                        downTimeDisplayText:  'absolute',
                        downTimeText: '开始了',
                        upTimeText: '还没开始',
                        showStyle:'zh'
                    },
                    anim: {
                        outAnim: 9999,
                        inAnim: 9999,
                        speedIn: 1,
                        speedOut: 0,
                    }
                },
                {
                    id: 65675,
                    name: '天气1',
                    elementId: '177412817',
                    num: 4,
                    type: 'weather',
                    visible: true,
                    lock: false,
                    css: {
                        top: 541,
                        left: 464,
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        width: 368,
                        height: 168,
                        fontSize: 16,
                        color: 'rgb(255, 255, 255)',
                        datefontSize: 24,
                        zIndex: 4,
                        backgroundColor: 'rgba(0, 0, 0, 1);',
                        opacity: 1,
                        transform: 'rotateZ(0deg)',
                        borderWidth: 0,
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,1)',
                        borderRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                    },
                    properties: {
                        startTime: 6,
                        endTime: 11,
                        stay: 5,
                        mode: [true, true, true, true, true, true, true],
                        updateTate: 2,
                        location: '116.400244, 39.92556',
                        locationName: "南山区",
                        style: 2,
                        timeFontSize: 22,
                    },
                    anim: {
                        outAnim: 9999,
                        inAnim: 9999,
                        speedIn: 1,
                        speedOut: 0,
                    }
                },
                {
                    id: 65676,
                    name: '文件1',
                    elementId: '3880528327',
                    num: 5,
                    type: 'file',
                    visible: true,
                    lock: false,
                    css: {
                        top: 527,
                        left: 890,
                        width: 416,
                        height: 234,
                        fontSize: 14,
                        zIndex: 5,
                        color: '#676767',
                        backgroundColor: 'rgba(0, 0, 0, 1);',
                        opacity: 1,
                        transform: 'rotateZ(0deg)',
                        textAlign: 'left',
                        borderWidth: 0,
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,1)',
                        borderRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                    },
                    properties: {
                        startTime: 0,
                        endTime: 16,
                        stay: 5,
                        children: [
                            {
                                src: 'http://cdn.linsn.com/01fYd16220907.jpg',
                                name: '微信图片_20220426215741.jpg',
                                size: 4233902,
                                id: 6382,
                                resolution: '3468*4001',
                                type: 'picture',
                                startTime: 0,
                                duration: 3,
                                animName: '随机',
                                outAnim: 9999,
                                inAnim: 9999,
                                speedIn: 1,
                                speedOut: 0,
                            },
                            {
                                src: 'https://cdn.linsn.com/04HYy16221010.png',
                                name: '微信图片_20220426215733.jpg',
                                size: 14266684,
                                id: 6380,
                                resolution: '9828*11339',
                                type: 'picture',
                                startTime: 4,
                                duration: 3,
                                animName: '随机',
                                outAnim: 9999,
                                inAnim: 9999,
                                speedIn: 1,
                                speedOut: 0,
                            },
                            {
                                src: 'https://cdn.linsn.com/01.%E7%A4%BE%E4%BC%9A%E5%85%AC%E5%BE%B7%E7%AF%87%2B30s_0916_01.mp4',
                                name: '01.社会公德篇+30s_0916_01.mp4',
                                size: 24600365,
                                id: 6375,
                                resolution: '610*900',
                                type: 'video',
                                startTime: 9,
                                duration: 20,
                                animName: '随机',
                                outAnim: 9999,
                                inAnim: 9999,
                                speedIn: 5,
                                speedOut: 5,
                            },
                        ],
                    },
                },
                {
                    id: 65976,
                    type: "analogTime",
                    name: '石英钟1',
                    elementId: '3880528647',
                    visible: true,
                    lock: false,
                    css: {
                        width: 300,
                        height: 300,
                        left: 180,
                        top: 630,
                    },
                    properties: {
                        startTime: 0,
                        endTime: 3,
                        stay: 3,
                        //数字大小
                        numberSize: "44",
                        //数字颜色
                        numberColor: "#d92626",
                        //数字类型； 类型1为1-12 类型2为：3 6 9 12
                        numberType: "1",
                        //时针颜色
                        hoursColor: "#222222",
                        //分针颜色
                        minutesColor: "#222222",
                        //秒针颜色
                        secondsColor: "#d92626",
                        //时标颜色
                        timeScaleColor: "",
                        //边框1颜色
                        frame1Color: "#222222",
                        //边框2颜色
                        frame2Color: "#c8c8c8",
                        //边框3颜色
                        frame3Color: "#fcfcfc",
                        //时标类型 triangle:时标三角 ;strip:时标条状; round:时标圆形
                        timeScaleType: "strip",
                        //文字顔色
                        fontColor: "#111111",
                        //文字大小
                        ampmSize: "40",
                        //时间星期文字大小
                        dateWeekSize: "25",
                        //边框1坐标
                        frame1CoordInate: [196, 196, 196],
                        //边框2坐标
                        frame2CoordInate: [196, 196, 166],
                        //边框3坐标
                        frame3CoordInate: [196, 196, 162],
                        //时针坐标
                        hoursCoordInate: [196, 91.72, 192.17, 100.33, 192.17, 233.67, 199.83, 233.67, 199.83, 100.33, 196, 91.72],
                        //分针坐标
                        minutersCoordInate: [196, 47.69, 193.75, 51.15, 193.75, 238.7, 198.25, 238.7, 198.25, 51.15, 196, 47.69],
                        //秒针1坐标
                        seconds1CoordInate: [195.92, 52.96, 194.92, 54.56, 194.86, 221.06, 196.86, 221.06, 196.92, 54.56, 195.92, 52.96],
                        //秒针2坐标
                        seconds2CoordInate: [193.22, 221.06, 5.5, 29],
                        //中心圆1
                        round1CoordInate: [196, 196, 16],
                        //中心圆2
                        round2CoordInate: [196, 196, 12],
                        //中心圆3
                        round3CoordInate: [196, 196, 9],
                        //时标三角
                        timeScale1CoordInate: [203, 45, 188, 45, 196, 56],
                        //时标条状
                        timeScale2CoordInate: [194, 45, 4, 9.7],
                        //时标圆形
                        timeScale3CoordInate: [322, 269, 5],
                        //刻度
                        timeScaleCoordInate: [195, 45, 1.2, 9.7],
                        //上午下午坐标
                        ampmCoordInate: [160, 155],
                        //日期坐标
                        dateCoordInate: [146, 250],
                        //星期坐标
                        weekCoordInate: [160, 280],
                        //是否显示ampm
                        isAmpm: true,
                        //是否显示日期
                        isDate: true,
                        //是否显示星期
                        isWeek: true,
                        dateFormat: 1,
                        dateStyle: 1,
                    },
                    anim: {
                        outAnim: 9999,
                        inAnim: 9999,
                        speedIn: 1,
                        speedOut: 0,
                    }
                },
                {
                    id: 65856,
                    type: "dazzleText",
                    name: '炫彩字1',
                    elementId: '3880528947',
                    visible: true,
                    lock: false,
                    css: {
                        width: 320,
                        height: 80,
                        left: 650,
                        top: 830,
                        textAlign: "center",
                        verticalAlign: "middle",
                        color: "rgb(255, 255, 255)",
                        fontSize: 24
                    },
                    properties: {
                        startTime: 0,
                        endTime: 3,
                        stay: 3,
                        content: '灵芯宇开发技术有限公司',
                        canvasHeight: 0,
                        canvasWidth: 0,
                        //滚动速度类型 0:按档位 1:按像素
                        scrollSpeedType: 0,
                        //滚动速度
                        scrollSpeed: 3,
                        //滚动时间
                        scrollDuration: 10,
                        //首尾间距
                        headTailSpacing: 10,
                        //滚动方向 0:无 1:环绕滚动 2:滚动一次 3:来回滚动
                        scrollDirection: 0,
                        //是否首尾相连
                        endToEnd: !0,
                        dazzle: {
                            //炫彩类型 1:缕空 2:水平彩色 3：垂直彩色
                            name: "缕空",
                            type: 1,
                            id: 1
                        },
                        imgName: "alpha-10f4b9.png"
                    },
                    anim: {
                        outAnim: 9999,
                        inAnim: 9999,
                        speedIn: 1,
                        speedOut: 0,
                    }
                }
            ],
        }
    ],
}
