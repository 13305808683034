/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import store from '@/store/'
import router from '@/router/router'
import { serialize } from '@/util/utils'
import { getToken } from '@/util/auth'
import website from '@/config/website'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { Base64 } from 'js-base64'
import { ElMessage } from 'element-plus'
axios.defaults.timeout = 10000
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500 // 默认的
}
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
    showSpinner: false,
})
//HTTPrequest拦截
axios.interceptors.request.use(
    (config) => {
        NProgress.start() // start progress bar
        const meta = config.meta || {}
        const isToken = meta.isToken === false
        let baseInfo = localStorage.getItem('userInfoEditor')
        baseInfo = JSON.parse(baseInfo)
        if (baseInfo) {
            config.headers['authorization'] = baseInfo.token
        }
        //headers中配置serialize为true开启序列化
        if (config.method === 'post' && meta.isSerialize === true) {
            config.data = serialize(config.data)
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
//HTTPresponse拦截
axios.interceptors.response.use(
    (res) => {
        NProgress.done()
        const status = res.data.code || 200
        const fang = res.data.success || false
        const statusWhiteList = website.statusWhiteList || []
        const message = res.data.message || '未知错误'
        //如果在白名单里则自行catch逻辑处理
        if (statusWhiteList.includes(status)) return Promise.reject(res)
        //如果是401则跳转到登录页面
        if (status === 401)
            ElMessage.error('token失效！')
        if (status === 400) {
            ElMessage.error(message)
            return false
        }

        return res
    },
    (error) => {
        NProgress.done()
        return Promise.reject(new Error(error))
    }
)

export default axios
