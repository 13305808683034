

function setAnim(el, value, instance) {
    var inAnim = value.inAnim
    var random1 = inAnim;
    if (inAnim == 9999) {
        random1 = Math.floor(Math.random() * 4) + 2;
    }
    var anim = instance.anims.find(anim => {
        return anim.id == random1
    })
    $(el).addClass(anim.value)
    $(el).css({
        "animation-duration": value.speedIn + 'ms'
    })
}
const xwyAnim = {
    mounted(el, { value, instance }) {
        setAnim(el, value, instance)
    },
}
export { xwyAnim } 