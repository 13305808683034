import * as Vue from 'vue'
import App from './App.vue'
let app = Vue.createApp(App)
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
import { delayLoadService } from './util/utils'
import router from './router/router'
import VueAxios from 'vue-axios'
import axios from './router/axios'
import Element from 'element-plus'
import { ClickOutside } from 'element-plus'
import 'element-plus/dist/index.css';
import 'ant-design-vue/dist/antd.css';
import { colorPicker } from '@/components/v3-color-picker/src'
import xwySliderInput from '@/components/slider-input/sliderInput.vue'
import xwySliderDotwrap from '@/components/slider-dotwrap/sliderDotwrap.vue'
import lxyInputColor from '@/components/selectColor/lxyInputColor.vue'
import { xwyAnim } from '@/components/anim/anim'
import store from './store'
import i18n from './lang'
import $ from 'jquery'

import 'default-passive-events'
import 'animate.css'
import './styles/element-variables.scss';
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { VueCropper } from "vue-cropper";
//default styles
import { iconfontUrl, iconfontVersion } from '@/config/env'


delayLoadService('/css/bootstrap.min.css', 'css', "bootstrap")
delayLoadService('/css/jquery-ui.min.css', 'css', "jquery-ui")
delayLoadService('/css/layui.css', 'css', null)
delayLoadService('/css/hint.min.css', 'css', null)


// 动态加载阿里云字体库
iconfontVersion.forEach((ele) => {
    delayLoadService(iconfontUrl.replace('$key', ele), 'css', null)
})
import './styles/common.scss'
app.component("xwySliderInput", xwySliderInput)
app.component("xwySliderDotwrap", xwySliderDotwrap)
app.component("lxyInputColor", lxyInputColor)
app.directive('xwy-colorpicker', colorPicker);
app.directive('xwy-anim', xwyAnim); // 只注册指令
app.directive('clickOutside', ClickOutside)

// 屏蔽错误信息
app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;
app.use(VueAxios, axios)
app.use(VueCropper)
app.use($)
app.use(i18n)
app.use(Element)
app.use(store)
app.use(router)
app.mount('#app')
