<template>
    <div class="base-slider horizontal" :style="{ width: width + 'px' }">
        <div class="slider-inner">
            <div class="slider-road Soft-UI concave"></div>
            <div class="slider-dotwrap left" v-if="value1 != null">
                <div class="slider-fill" v-click-l="value1" :style="{ width: (value1 / max) * 100 + '%' }"></div>
                <div class="slider-dot" v-drag-l="value1" :style="{ left: (value1 / max) * 100 + '%' }"><span class="dot-mark"></span></div>
            </div>
            <div class="slider-dotwrap right" v-if="value2 != null">
                <div class="slider-fill" v-click-r="value2" :style="{ width: (value2 / max) * 100 + '%' }"></div>
                <div class="slider-dot" v-drag-r="value2" :style="{ right: (value2 / max) * 100 + '%' }"><span class="dot-mark"></span></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    directives: {
        'drag-l': function (el, { instance, value }) {
            var max
            instance.value2 ? max = parseFloat(instance.max) - instance.value2 : max = parseFloat(instance.max)
            el.onmousedown = (e) => {
                var min = parseFloat(instance.min)
                var initX = e.pageX
                var initValue = value
                var step = instance.step
                e = e || window.event;
                e.preventDefault();
                document.onmousemove = (e1) => {
                    e1 = e1 || window.event;
                    e1.preventDefault();
                    var moveX = (e1.pageX - initX) / ((10 * 1) / max)
                    var val = void 0
                    if (step) {
                        val = Number(Number(initValue + moveX * step).toFixed(1))
                    } else {
                        val = Number(initValue + moveX * 1)
                    }
                    if (val > max) {
                        val = max
                    } else if (val < min) {
                        val = min
                    }
                    instance.$emit('update:value1', Number(val))
                }
                document.onmouseup = e => {
                    document.onmousemove = null
                    document.onmouseup = null
                }
            }
        },
        'drag-r': function (el, { instance, value }) {
            var max
            instance.value1 ? max = parseFloat(instance.max) - instance.value1 : max = parseFloat(instance.max)
            el.onmousedown = (e) => {
                var min = parseFloat(instance.min)
                var initX = e.pageX
                var initValue = value
                var step = instance.step
                e = e || window.event;
                e.preventDefault();
                document.onmousemove = (e1) => {
                    e1 = e1 || window.event;
                    e1.preventDefault();
                    var moveX = (initX - e1.pageX) / ((10 * 1) / max)
                    var val = void 0
                    if (step) {
                        val = Number(Number(initValue + moveX * step).toFixed(1))
                    } else {
                        val = Number(initValue + moveX * 1)
                    }
                    if (val > max) {
                        val = max
                    } else if (val < min) {
                        val = min
                    }
                    console.log(val)
                    instance.$emit('update:value2', Number(val))
                }
                document.onmouseup = e => {
                    document.onmousemove = null
                    document.onmouseup = null
                }
            }
        },
        'click-l': function (el, { instance, value }) {
            var max
            instance.value1 ? max = parseFloat(instance.max) - instance.value1 : max = parseFloat(instance.max)
            var min = parseFloat(instance.min)
            var step = instance.step
            el.onclick = (e) => {
                e = e || window.event;
                e.preventDefault();
                var moveX = (e.offsetX * max / 100 / step)
                console.log(moveX)
                var val = void 0
                if (step) {
                    val = Number(Number(moveX).toFixed(1))
                } else {
                    val = Number(moveX * 1)
                }
                if (val > max) {
                    val = max
                } else if (val < min) {
                    val = min
                }
                instance.$emit('update:value1', Number(val))
            }
        },
        'click-r': function (el, { instance, value }) {
            el.onclick = (e) => {
                e = e || window.event;
            }
        }
    },
    props: {
        width: { type: Number, default: 120 },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100000 },
        unit: { type: String, default: '%' },
        step: { type: String, default: '1' },
        disabled: { type: Boolean, default: false },
        value1: { type: Number, require: true },
        value2: { type: Number, require: false },
        onChange: { type: Function }
    },
    emits: ['update:value1', 'update:value2', 'onChange'],
}
</script>
<style lang="less" scoped>
.base-slider {
    width: 100%;
}

.base-slider.horizontal {
    width: 90px;
}
.base-slider {
    display: inline-block;
    float: right;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.base-slider .slider-inner {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}
.base-slider.horizontal .slider-road {
    display: block;
    width: 100%;
    height: 12px;
    border-radius: 12px;
    background-color: #0b0c0e;
}

.base-slider .slider-road {
    pointer-events: none;
}
[data-theme='light'] .Soft-UI.concave,
[data-theme='light'] .Soft-UI.UI-active:active {
    box-shadow: inset 2px 2px 3px 0 rgb(191 199 204 / 80%), inset -2px -2px 4px 0 #fff;
}
[data-theme='light'] .Soft-UI {
    border: 1px solid hsla(0, 0%, 100%, 0.4);
    background-color: var(--backColor);
    transition: all 0.1s;
    box-shadow: 2px 2px 3px 0 rgb(191 199 204 / 80%), -2px -2px 4px 0 #fff;
}
.base-slider .slider-dotwrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.base-slider.horizontal .slider-fill {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 0;
    height: 12px;
    padding: 3px;
    background-clip: content-box;
    background-color: #0fcdcf;
}
.base-slider.horizontal .slider-dot {
    position: absolute;
    top: -1px;
    z-index: 2;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #0fcdcf;
    pointer-events: auto;
    box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 15%);
}
.base-slider .slider-dotwrap.left .slider-dot {
    margin-left: -7px;
}
.base-slider .slider-dotwrap.right .slider-dot {
    margin-right: -7px;
}
.base-slider .dot-mark {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: auto;
    margin-left: 4px;
    transform: translateY(-5px) translateX(-50%);
}
.base-slider.horizontal .slider-dotwrap.right .slider-fill {
    border-radius: 12px;
    left: auto;
    right: 0;
}

.base-slider.horizontal .slider-dotwrap.left .slider-fill {
    border-radius: 12px;
}

.base-slider .slider-dotwrap.right .slider-dot,
.base-slider .slider-dotwrap.right .slider-fill {
    background-color: #f7b500 !important;
}
.base-slider .slider-dotwrap.right .slider-dot,
.base-slider .slider-dotwrap.right .slider-fill {
    background-color: #f7b500 !important;
}
.base-slider .dot-mark {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: auto;
    margin-left: 4px;
    transform: translateY(-5px) translateX(-50%);
}
</style>