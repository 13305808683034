import { createI18n } from 'vue-i18n';
import elementEnLocale from 'element-plus/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn' // element-ui lang
import zhLocale from './zh'
import enLocale from './cn'
import { getStore } from '@/util/store'
const messages = {
    en: {
        ...enLocale,
        ...elementEnLocale,
    },
    zh: {
        ...zhLocale,
        ...elementZhLocale,
    },
}

const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    fallbackLocale: 'zh',
    locale: getStore({ name: 'language' }) || 'zh',
    messages,
})

export default i18n
