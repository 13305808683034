import { setStore, getStore, removeStore } from '@/util/store'
//import { setLang } from '@/util/auth'
import website from '@/config/website'

const common = {
    state: {
        language: getStore({ name: 'language' }) || 'zh',
        query: { i: 0 },
        isFullScren: false,
        isLock: getStore({ name: 'isLock' }) || false,
        colorName: getStore({ name: 'colorName' }) || '#409EFF',
        themeEditName: getStore({ name: 'themeEditName' }) || 'theme-black',
        lockPasswd: getStore({ name: 'lockPasswd' }) || '',
        website: website,
        zoom: getStore({ name: 'editorScale' }) || 0.65,
        cellSize: getStore({ name: 'cellSize' }) || 36,
        gridColor: getStore({ name: 'gridColor' }) || "rgba(150, 150, 150, 0.2)",
    },
    mutations: {
        SET_LANGUAGE: (state, language) => {
            state.language = language
            setLang(language)
            setStore({
                name: 'language',
                content: state.language,
            })
        },
        SET_ZOOM: (state, zoom) => {
            state.zoom = zoom
            setStore({
                name: 'editorScale',
                content: state.zoom,
            })
        },
        SET_GRID: (state, grid) => {
            state.grid = grid;
            setStore({
                name: "assistGrid",
                content: state.grid,
            })
        },
        SET_FULLSCREN: (state) => {
            state.query = state.query
        },
        SET_FULLSCREN: (state) => {
            state.isFullScren = !state.isFullScren
        },
        SET_LOCK: (state) => {
            state.isLock = true
            setStore({
                name: 'isLock',
                content: state.isLock,
                type: 'session',
            })
        },
        SET_COLOR_NAME: (state, colorName) => {
            state.colorName = colorName
            setStore({
                name: 'colorName',
                content: state.colorName,
            })
        },
        SET_THEME_EDIT_NAME: (state, themeName) => {
            state.themeName = themeName
            setStore({
                name: 'themeEditName',
                content: 'theme-white',
            })
        },
        SET_LOCK_PASSWD: (state, lockPasswd) => {
            state.lockPasswd = lockPasswd
            setStore({
                name: 'lockPasswd',
                content: state.lockPasswd,
                type: 'session',
            })
        },
        CLEAR_LOCK: (state) => {
            state.isLock = false
            state.lockPasswd = ''
            removeStore({
                name: 'lockPasswd',
                type: 'session',
            })
            removeStore({
                name: 'isLock',
                type: 'session',
            })
        },
    },
}

export default common
