/**
 * 判断路由是否相等
 * @param {*} obj
 * @param {*} obj1
 */
//表单序列化
export const serialize = data => {
    let list = [];
    Object.keys(data).forEach(ele => {
        list.push(`${ele}=${data[ele]}`)
    })
    return list.join('&');
};

export const diff = function (obj1, obj2) {
    delete obj1.close
    var o1 = obj1 instanceof Object
    var o2 = obj2 instanceof Object
    if (!o1 || !o2) {
        /*  判断不是对象  */
        return obj1 === obj2
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false
        //Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
    }

    for (var attr in obj1) {
        var t1 = obj1[attr] instanceof Object
        var t2 = obj2[attr] instanceof Object
        if (t1 && t2) {
            return diff(obj1[attr], obj2[attr])
        } else if (obj1[attr] !== obj2[attr]) {
            return false
        }
    }
    return true
}

export const getObjType = (obj) => {
    var toString = Object.prototype.toString
    var map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object',
    }
    if (obj instanceof Element) {
        return 'element'
    }
    return map[toString.call(obj)]
}

/**export const deepClone = function (data) {
    var type = getObjType(data)
    var obj
    if (type === 'array') {
        obj = []
    } else if (type === 'object') {
        obj = {}
    } else {
        //不再具有下一层次
        return data
    }
    if (type === 'array') {
        for (var i = 0, len = data.length; i < len; i++) {
            obj.push(deepClone(data[i]))
        }
    } else if (type === 'object') {
        for (var key in data) {
            obj[key] = deepClone(data[key])
        }
    }
    return obj
}**/

export const setTheme = function (name) {
    document.body.className = name
}

/**
 * 动态插入css或js
 * @param {*} url 路径
 * @param {*} type 类型
 * @param {*} title 标题
 */
export const delayLoadService = function (url, type, title) {
    return new Promise(function (prom) {
        var data = null,
            thisScript = null,
            thisLink = null,
            index = 0
        if (
            (type || (type = url.substr(url.lastIndexOf('.') + 1)), 'js' === type)
        ) {
            var script = document.getElementsByTagName('script')
            for (index = 0; index < script.length; index++)
                ((script[index].src && script[index].src.indexOf(url) != -1) ||
                    script[index].title == title) &&
                    ((thisScript = script[index]), prom(script(index)))
            thisScript ||
                ((data = document.createElement('script')),
                    (data.rel = 'text/javascript'),
                    (data.src = url),
                    title && (data.title = title))
        } else if (type === 'css') {
            var link = document.getElementsByTagName('link');
            for (index = 0; index < link.length; index++)
                ((link[index].src && link[index].src.indexOf(url) != -1) ||
                    link[index].title == title) &&
                    ((thisLink = link[index]), prom(link(index)))
            thisLink ||
                ((data = document.createElement('link')),
                    (data.rel = 'stylesheet'),
                    (data.type = 'text/css'),
                    (data.href = url),
                    title && (data.title = title))
        } else prom(!1)
        if (data) {
            var head = document.getElementsByTagName('head')[0]
            head.appendChild(data),
                (data.onload = function () {
                    prom(data)
                })
        }
    })
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
    if (fullscreenEnable()) {
        exitFullScreen()
    } else {
        reqFullScreen()
    }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
    function listen() {
        callback()
    }
    document.addEventListener('fullscreenchange', function () {
        listen()
    })
    document.addEventListener('mozfullscreenchange', function () {
        listen()
    })
    document.addEventListener('webkitfullscreenchange', function () {
        listen()
    })
    document.addEventListener('msfullscreenchange', function () {
        listen()
    })
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
    var isFullscreen =
        document.isFullScreen ||
        document.mozIsFullScreen ||
        document.webkitIsFullScreen
    return isFullscreen
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen()
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen()
    } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
    }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.exitFullScreen()
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.webkitCancelFullScreen()
    } else if (document.documentElement.mozRequestFullScreen) {
        document.mozCancelFullScreen()
    }
}

//网络text文件转文件流到页面展示
export const loadFileDelayHtml = (src, name) => {
    return new Promise((resolve) => {
        const xml = new XMLHttpRequest();
        xml.open('GET', src, true)
        xml.responseType = "blob"
        xml.onload = () => {
            if (xml.status === 200) {
                const file = new File([xml.response], name[name.length - 1], { type: xml.response.type })
                const reader = new FileReader()
                reader.onload = () => {
                    if (reader.result) {
                        resolve(reader.result)
                    }
                }
                reader.readAsText(file, "UTF-8")
            }
        }
        xml.send()
    })
}

export const guid = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


const trackHorizontalScroll = (t) => {
    console.log(this)
    var e = this.$refs.tracksDesk;
    return e ? (isNaN(t) || (e.scrollLeft = Number(t)), e.scrollLeft) : 0
}

const fix = (t, e) => {
    var i = isNaN(e) ? 4 : Math.floor(e),
        a = "1" + "0".repeat(i),
        n = unit2px(t);
    return Math.floor(n * a) / a
}
const isObject = (t) => {
    return "[object Object]" === Object.prototype.toString.call(t)
}

const unit2px = (t) => {
    var e = 72,
        i = 2.541,
        a = 0,
        n = String(t);
    switch (!0) {
        case n.indexOf("mm") >= 0:
            a = Number(n.slice(0, -2)) / 10 / i * e;
            break;
        case n.indexOf("cm") >= 0:
            a = Number(n.slice(0, -2)) / i * e;
            break;
        case n.indexOf("in") >= 0:
            a = Number(n.slice(0, -2)) * e;
            break;
        case n.indexOf("px") >= 0:
        case n.indexOf("em") >= 0:
            a = Number(n.slice(0, -2));
            break;
        default:
            a = Number(n);
            break
    }
    return isNaN(a) && (a = 0), a
}

const mouseDownMoveEvent = (t, e) => {
    var i = function (e) {
        "function" === typeof t && t(e)
    }, a = function t(a) {
        document.removeEventListener("mousemove", i), document.removeEventListener("mouseup", t), "function" === typeof e && e(a)
    };
    document.addEventListener("mousemove", i), document.addEventListener("mouseup", a)
}

const NumberRange = (value, min, max) => {
    return isNaN(value) ? value : !isNaN(min) && Number(value) < Number(min) ? Number(min) : !isNaN(max) && Number(value) > Number(max) ? Number(max) : Number(value)
}

const isFunctionCall = () => {
    var t = Array.from(arguments),
        e = this,
        i = t[0],
        a = t.slice(1);
    return Ce.isObject(t[0]) && (e = t[0], i = t[1], a = t.slice(2)), !!Ce.isFunction(i) && i.apply(e, a)
}

const base64ToBlob = (t) => {
    if (t && "string" === typeof t) {
        var e = t.split(","),
            i = e[0].match(/:(.*?);/)[1],
            a = atob(e[1]),
            n = a.length,
            o = new Uint8Array(n);
        while (n--) o[n] = a.charCodeAt(n);
        return new Blob([o], {
            type: i
        })
    }
    return new Blob
}
const getFileUrl = (t) => {
    var e = null;
    return void 0 != window.createObjectURL ? e = window.createObjectURL(t) : void 0 != window.URL ? e = window.URL.createObjectURL(t) : void 0 != window.webkitURL && (e = window.webkitURL.createObjectURL(t)), e
}
const deepClone = (t) => {
    var e;
    if ("object" === t)
        if (Array.isArray(t))
            for (var i in e = [], t) e.push(Ce.deepClone(t[i]));
        else if (null === t) e = null;
        else if (t.constructor === RegExp) e = t;
        else
            for (var a in e = {}, t) e[a] = Ce.deepClone(t[a]);
    else e = t;
    return e
}

export default {
    trackHorizontalScroll,
    fix,
    unit2px,
    isObject,
    mouseDownMoveEvent,
    NumberRange,
    isFunctionCall,
    deepClone,
    base64ToBlob,
    getFileUrl
}
