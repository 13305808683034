import Cookies from 'js-cookie'
const TokenKey = 'x-access-token'
var dateMiuntes = new Date(new Date().getTime() + 120 * 60 * 1000)
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, expiresIn) {
  dateMiuntes = (expiresIn && expiresIn) || dateMiuntes
  Cookies.set(TokenKey, token, { expires: dateMiuntes, path: '/' })
}

export function setLang(language, expiresIn) {
  dateMiuntes = (expiresIn && expiresIn) || dateMiuntes
  Cookies.set('linsn-language', language, { expires: dateMiuntes, path: '/' })
}

export function removeToken() {
  Cookies.remove(TokenKey)
}
