
export default {
    '7': '文本',
    '4': '图片',
    p: '图集',
    '3': '背景',
    v: '视频',
    w: '天气',
    r: '日期',
    dazzleText: '炫彩字',
    analogTime: '石英钟',
    web: '网页',
    file: '文件',
    '100001': '文本',
    '100002': '图片',
    '100003': '背景',
    '100004': '音乐',
    '100005': '流媒体',
    '100006': '图集',
    '100007': '计时',
    '100008': '组件',
    '100009': '数字时钟',
    '100010': '天气',
    '100011': '网页',
    '100012': '媒体窗',
    '100013': '功能模板',
    '100014': '保存',
    '100015': '发布',
    '100016': '石英钟',
    '100017': '炫彩字',
    '100018': '保存并关闭',
    '200016': '点击弹出组件属性面板',
    '200017': '多选属性',
    '200018': '组合属性',
    '200019': '功能模版',
    '200020': '页面属性',
    '200021': '图层管理',
    '200022': '节目管理',
    '200023': '添加',
    '200024': '组合',
    '200025': '分组',
    '200026': '拆分',
    '200027': '复制',
    '200028': '删除',
    '200029': '纯色背景',
    '200030': '基础属性',
    '200031': '节目名',
    '200032': '分辨率',
    '200033': '节目页',
    '200034': '图片背景',
    '200035': '添加',
    '200036': '裁切',
    '200037': '删除',
    '200038': '更换',
    '200039': '背景类型',
    '200040': '透明度',
    '200041': '背景效果',
    '200042': '计划时间表',
    '200043': '提示：此时间设置指的是每个节目页的播放时长',
    '200044': '更换素材',
    '300044': '节目模板',
    '300045': '换肤',
    '300046': '请选择你喜欢的皮肤',
    '300047': '典雅白',
    '300048': '高端黑',
    '400049': '加载中，请稍后',
    '500050': '组合',
    '500051': '编辑',
    '500052': '裁切',
    '500053': '粘贴',
    '500054': '全屏',
    '500055': '取消全屏',
    '500056': '样式',
    '500057': '动画',
    '500058': '触发',
    '500059': '添加音效',
    '500060': '添加链接',
    '500061': '复制动画',
    '500062': '粘贴动画',
    '500063': '删除动画',
    '500064': '复制触发',
    '500065': '粘贴触发',
    '500066': '删除触发',
    '500067': '置顶',
    '500068': '置底',
    '500069': '上移',
    '500070': '下移',
    '500071': '预览',
    '600071': '背景设置',
    '600072': '网格开关',
    '600073': '网格颜色',
    '600074': '智能参考',
    '600075': '吸附效果',
    '600076': '放大',
    '600077': '缩小',
    '600078': '撤消',
    '600079': '恢复',
    '600080': '刷新预览',
    '600081': '播放时长',
    '600082': '秒',
    '600083': '等待节目播完',
    '600084': '网格设置',
    '600085': '复制当前页',
    '600086': '解锁',
    '600087': '锁定',
    '600088': '快捷键',
    '700001': '组件设置',
    '700002': '点击收起组件属性面板',
    '700003': '外观',
    '700004': '上移一层',
    '700005': '下移一层',
    '700006': '水平铺满',
    '700007': '取消水平铺满',
    '700008': '垂直铺满',
    '700009': '取消垂直铺满',
    '700010': '左对齐',
    '700011': '水平居中对齐',
    '700012': '右对齐',
    '700013': '上对齐',
    '700014': '垂直居中对齐',
    '700015': '下对齐',
    '700016': '旋转',
    '700017': '度',
    '700018': '注：旋转功能为终端版本1.0.6.8支持,且是平铺效果',
    '700019': '透明度',
    '700020': '大小',
    '700021': '宽',
    '700022': '高',
    '700023': '位置',
    '700024': '边框',
    '700025': '边框样式',
    '700026': '无',
    '700027': '直线',
    '700028': '破折线',
    '700029': '点状线',
    '700030': '双划线',
    '700031': '3D凹槽',
    '700032': '3D垄状',
    '700033': '3D内嵌',
    '700034': '3D外嵌',
    '700035': '边框颜色',
    '700036': '边框尺寸',
    '700037': '边框弧度',
    '700038': '边距',
    '700039': '阴影',
    '700040': '阴影颜色',
    '700041': '阴影大小',
    '700042': '阴影方向',
    '700043': '阴影模糊',
    '700044': '裁切形状',
    '700045': '裁切比例',
    '700046': '确定',
    '700047': '取消',
    '700048': '位置与尺寸',
    '700049': '外阴影',
    '700050': '内阴影',
    '700051': '横向',
    '700052': '纵向',
    '700053': '模糊',
    '700054': '',
    '700055': '',
    '800001': '语言',
    '800002': '中文',
    '800003': '日期',
    '800004': '年份格式',
    '800005': '四位年',
    '800006': '两位年',
    '800007': '显示风格',
    '800008': '星期',
    '800009': '时间',
    '800011': '小时',
    '800012': '时间风格',
    '800013': '显示方式',
    '800014': '单行',
    '800015': '多行',
    '800016': '文本设置',
    '800017': '添加文件',
    '800018': '进场特效',
    '800019': '停留时间',
    '800020': '动画时长',
    '800021': '清场特效',
    '800022': '清场速度',
    '800023': '文本内容',
    '800024': '字体',
    '800025': '默认字体',
    '800026': '字号',
    '800027': '文本属性',
    '800028': '单行文本',
    '800029': '多行文本',
    '800030': '文字颜色',
    '800031': '加粗',
    '800032': '倾斜',
    '800033': '下划线',
    '800034': '居左',
    '800035': '居中',
    '800036': '居右',
    '800037': '顶端对齐',
    '800038': '垂直居中',
    '800039': '底端对齐',
    '800040': '清除样式',
    '800041': '滚动方式',
    '800042': '环绕滚动',
    '800043': '来回滚动',
    '800044': '滚动一次',
    '800045': '滚动方向',
    '800046': '向左滚动',
    '800047': '向右滚动',
    '800048': '向上滚动',
    '800049': '向下滚动',
    '800050': '滚动速度',
    '800051': '行高',
    '800052': '字距',
    '800053': '组合操作',
    '800054': '输入框',
    '800055': '提交按钮',
    '800056': '单选',
    '800057': '多选',
    '800058': '下拉列表',
    '800059': '评分',
    '800060': '显示设置',
    '800061': '显示',
    '800062': '隐藏',
    '800063': '炫彩类型',
    '800064': '缕空图案',
    '800065': '首尾相接',
    '800066': '首尾间距',
    '800067': '滚动方向',
    '800068': '滚动速度',
    '800069': '播放时长',
    '800070': '按像素',
    '800071': '按档位',
    '900001': '终端名称',
    '900002': '终端MAC',
    '900003': '终端类型',
    '900004': '终端IP',
    '900005': '终端状态',
    '900006': '在线',
    '900007': '离线',
    '900008': '发布',
    '900009': '图片库',
    '900010': '全部',
    '900011': '图片',
    '900012': '视频',
    '900013': '文本',
    '900014': '请文件名输入关键字',
    '900015': '搜索',
    '900016': '音乐',
    '900017': '添加到',
    '900018': '素材库',
    '900019': '我的图库',
    '900020': '图片库',
    '900021': '我的文件',
    '900022': '标签管理',
    '900023': '大小不超过3M，支持格式：JPG、PNG、GIF，一次最多上传20张',
    '900024': '页面删除后无法还原',
    '900025': '坚持删除',
    '900026': '稍后决定',
    '900027': '场景中至少保留一个页面',
    '900028': '知道了',
    '900029': '页面保存失败',
    '900030': '数字大小',
    '910000': '数字颜色',
    '910001': '时针颜色',
    '910002': '分针颜色',
    '910003': '秒针颜色',
    '910004': '时标颜色',
    '910005': '边框1颜色',
    '910006': '边框2颜色',
    '910007': '边框3颜色',
    '910008': '时标类型',
    '910009': '圆形',
    '910010': '三角',
    '910011': '条状',
    '910012': '文字颜色',
    '910013': '英文',
    '910014': '字符',
    '910015': '分割样式',
    '910016': '我的',
    '910017': '节目设置',
    '910018': '素材列表',
    '910019': '最近使用',
    '910020': '回收站',
    '910021': '本地上传',
    '910022': '视频',
    '910023': '图片',
    '910024': '音乐',
    '910025': '导入文本',
    '910026': '本地导入',
    '910027': '素材导入',
    '910028': '天气',
    '910029': '温度',
    '910030': '风力',
    '910031': '空气',
    '910032': '紫外线',
    '910033': '当前温度',
    '910034': '湿度',
    '910035': '天气地点',
    '910036': '更新时间',
    '910037': '小时',
    '910038': '风格',
    '910039': '显示内容',
    '910040': '播放次数',
    '910041': '开始时间',
    '910042': '停留时间',
    '910043': '最近上传',
    '910044': '立即使用',
    '910045': '自定义',
    '910046': '计时类型',
    '910047': '计时开始',
    '910048': '计时截止',
    '910049': '倒计时',
    '910050': '正计时',
    '910051': '天',
    '910052': '时',
    '910053': '分',
    '910054': '秒',
    '910055': '显示精度',
    '910056': '文字描述',
    '910057': '截止后显示内容',
    '910058': '开始前显示',
    '910059': '开始了',
    '910060': '还没开始',
    '910061': '多选操作',
    '910062': '优先模式',
    '910063': '显示设置',
    '910064': '音乐优先',
    '910065': '视频优先',
    '910066': '显示画面',
    '910067': '隐藏画面',
    '910068': '结束时间',
    '910069': '文本',
    '910070': '上传中...',
    '910071': '当前储存空间不足20KB，请充值后上传！！！',
    '910072': '文件名称限制在50个字符内',
    '910073': '视频文件大小限制在',
    '910074': '图片文件大小限制在',
    '910075': '文件个数限制在',
    '910076': '当前所选文件大于 剩余储存量，请重新选择或充值后上传！！！',
    '910077': '内',
    '910078': '上传失败',
    '910079': '上传成功',
    '910080': '单击可返回节目列表',
    '910081': '播放属性',
    '910082': '是否保存并关闭此页面？',
    '910083': '绝对时间',
    '910084': '相对时间',
    '910085': '截止后显示',
    '910086': '计时后显示',
    '910087': '计时至',
    '910088': '展示样式',
    '910089': '展示效果',
    '910090': '',
}
