<template>
    <lxy-notification></lxy-notification>
    <router-view />
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
@import "@/styles/form.scss";
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
body textarea {
    background: #EFF0F4!important;
    border: 0;
    box-shadow: none!important;
}
/*el-selct边框 box-shadow*/
body input {
    background: #EFF0F4!important;
    border: 0;
    box-shadow: none!important;
}
/* el-select 右侧*/
body .el-input__suffix-inner {
    background: #EFF0F4!important;
}
/*地图*/
body .el-input-site {
    background: #EFF0F4!important;
}
/*字体放大缩小*/
body .zoom-in {
    background: #EFF0F4!important;
}
body .zoom-out {
    background: #EFF0F4!important;
}
body .el-input__prefix {
    background: #EFF0F4!important;
}
body .lxy_but {
    background: #EFF0F4!important;
}
body .page-res .fr{
   display: flex;
    width: 220px!important;
    justify-content: flex-end;
    align-items: center;
}
body .page-res .save{
    width: 70px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    color: #ffffff;
    background-color: #3A5BF0;
    padding: 8px 20px;
}
body .page-res .cancel{
    width: 70px;
    text-align: center;
    display: block;
    border-radius: 4px;
    font-size: 12px;
    color: #ffffff;
    background-color: #FD406D;
    padding: 8px 20px;
}




</style>
