
import * as Vuex from 'vuex'
import getters from './getters'
import common from './modules/common'

const store = Vuex.createStore({
    modules: {
        common,
    },
    getters,
})
export default store
