export default {
    '7': 'Text',
    '4': 'Picture',
    p: 'Atlas',
    '3': 'Background',
    v: 'Video',
    w: 'Weather',
    r: 'Time',
    web: 'Web',
    file: 'File',
    '100001': 'Text',
    '100002': 'Picture',
    '100003': 'Background',
    '100004': 'Music',
    '100005': 'Media',
    '100006': 'Atlas',
    '100007': 'Timing',
    '100008': 'Assembly',
    '100009': 'Time',
    '100010': 'Weather',
    '100011': 'Web',
    '100012': 'File',
    '100013': 'Function template',
    '100014': 'Save',
    '100015': 'Public',
    '200016': 'Click to pop up the component property panel',
    '200017': 'Multiple choice attribute',
    '100018': 'Save and close',
    '200018': 'Composite properties',
    '200019': 'Function template',
    '200020': 'Page properties',
    '200021': 'Layer management',
    '200022': 'Program management',
    '200023': 'Add',
    '200024': 'Combination',
    '200025': 'Group',
    '200026': 'Split',
    '200027': 'Copy',
    '200028': 'Delete',
    '200029': 'Solid background',
    '200030': 'Basic attributes',
    '200031': 'Program name',
    '200032': 'Resolution',
    '200033': 'Program page',
    '200034': 'Picture background',
    '200035': 'Add picture',
    '200036': 'Cut pictures',
    '200037': 'Delete pictures',
    '200038': 'Change picture',
    '200039': 'Background Type',
    '200040': 'Transparency',
    '200041': 'Background effect',
    '200042': 'Schedule',
    '200043':
        'Tip: this time setting refers to the playing time of each program page',
    '200044': 'Change material',
    '300044': 'Program template',
    '300045': 'Skin peeler',
    '300046': 'Please choose the skin you like',
    '300047': 'Elegant white',
    '300048': 'High end black',
    '400049': 'Loading, please wait',
    '500050': 'Combination',
    '500051': 'Edit',
    '500052': 'Cutting',
    '500053': 'Paste',
    '500054': 'Full screen',
    '500055': 'Cancel full screen',
    '500056': 'Style',
    '500057': 'Animation',
    '500058': 'Trigger',
    '500059': 'Add sound',
    '500060': 'Add link',
    '500061': 'Copy animation',
    '500062': 'Paste animation',
    '500063': 'Delete animation',
    '500064': 'Copy trigger',
    '500065': 'Paste trigger',
    '500066': 'Delete trigger',
    '500067': 'Top',
    '500068': 'Set the bottom',
    '500069': 'Move up',
    '500070': 'Move down',
    '500071': 'View',
    '600071': 'Background set',
    '600072': 'Grid switch',
    '600073': 'Grid color',
    '600074': 'Intelligent reference',
    '600075': 'Adsorption effect',
    '600076': 'Enlarge',
    '600077': 'Narrow',
    '600088': 'Shortcut key',
    '600078': 'Undo',
    '600079': 'Recovery',
    '600080': 'Refresh view',
    '600081': 'Play duration',
    '600082': 'Sec',
    '600083': 'Waiting for the program to finish',
    '600084': 'Grid settings',
    '600085': 'Copy current page',
    '600086': 'Unlock',
    '600087': 'Locking',
    '700001': 'Component settings',
    '700002': 'Click to collapse the component property panel',
    '700003': 'Appearance',
    '700004': 'Bring Forward',
    '700005': 'Send backward',
    '700006': 'Horizontal paving',
    '700007': 'Cancel horizontal paving',
    '700008': 'Vertical paving',
    '700009': 'Cancel vertical paving',
    '700010': 'Align left',
    '700011': 'Align horizontal center',
    '700012': 'Align right',
    '700013': 'Align up',
    '700014': 'Align vertical center',
    '700015': 'Bottom justify',
    '700016': 'Rotate',
    '700017': 'Degree',
    '700018':
        'Note: the rotation function is supported by terminal version 1.0.6.8, and it is tile effect',
    '700019': 'Transparency',
    '700020': 'Size',
    '700021': 'Width',
    '700022': 'Height',
    '700023': 'Position',
    '700024': 'Frame',
    '700025': 'Border style',
    '700026': 'No',
    '700027': 'Line',
    '700028': 'Broken line',
    '700029': 'Dotted',
    '700030': 'Double line',
    '700031': '3D Groove',
    '700032': '3D ridge',
    '700033': '3D embedded',
    '700034': '3D embedded',
    '700035': 'Border color',
    '700036': 'Border size',
    '700037': 'Border radian',
    '700038': 'Margin',
    '700039': 'Shadow',
    '700040': 'Shadow color',
    '700041': 'Shadow size',
    '700042': 'Shadow direction',
    '700043': 'Shadow blur',
    '700044': 'Cut shape',
    '700045': 'Cut ratio',
    '700046': 'Ok',
    '700047': 'Cancel',
    '700048': 'Basics',
    '800001': 'Language',
    '800002': 'China',
    '800003': 'Date',
    '800004': 'Year format',
    '800005': 'Four years',
    '800006': 'Two years',
    '800007': 'Display style',
    '800008': 'Week',
    '800009': 'Time',
    '800011': 'Hour',
    '800012': 'Time style',
    '800013': 'Display mode',
    '800014': 'One way',
    '800015': 'Multiline',
    '800016': 'Text settings',
    '800017': 'Add file',
    '800018': 'Entry effect',
    '800019': 'Residence time',
    '800020': 'Special effects speed',
    '800021': 'Clearance effect',
    '800022': 'Clearance speed',
    '800023': 'Text content',
    '800024': 'Font',
    '800025': 'Default font',
    '800026': 'Word size',
    '800027': 'Text properties',
    '800028': 'Single line text',
    '800029': 'Multiline text',
    '800030': 'Text color',
    '800031': 'Bold',
    '800032': 'Tilt',
    '800033': 'Underline',
    '800034': 'Left',
    '800035': 'Center',
    '800036': 'Right',
    '800037': 'Top align',
    '800038': 'Vertical center',
    '800039': 'Bottom alignment',
    '800040': 'Clear style',
    '800041': 'Scrolling mode',
    '800042': 'Scroll around',
    '800043': 'Scroll back and forth',
    '800044': 'Scroll once',
    '800045': 'Rolling direction',
    '800046': 'Scroll left',
    '800047': 'Scroll right',
    '800048': 'Scroll up',
    '800049': 'Scroll down',
    '800050': 'Rolling speed',
    '800051': 'Row height',
    '800052': 'Word spacing',
    '800053': 'Combination operation',
    '800054': 'Input box',
    '800055': 'Submit button',
    '800056': 'Single choice',
    '800057': 'Multiple choice',
    '800058': 'Drop down list',
    '800059': 'Score',
    '800060': 'Display set',
    '800061': 'Display',
    '800062': 'Hide',
    '900001': 'Terminal name',
    '900002': 'Terminal MAC',
    '900003': 'Terminal Type',
    '900004': 'Terminal IP',
    '900005': 'Terminal status',
    '900006': 'On-line',
    '900007': 'Off-line',
    '900008': 'Public',
    '900009': 'Photo gallery',
    '900010': 'All',
    '900011': 'Picture',
    '900012': 'Video',
    '900013': 'Text',
    '900014': 'Please enter a keyword for the file name',
    '900015': 'Search',
    '900016': 'Music',
    '900017': 'Add to',
    '900018': 'Material library',
    '900019': 'My gallery',
    '900020': 'Photo gallery',
    '900021': 'My files',
    '900022': 'Label management',
    '900023':
        'Size no more than 3m, support format: JPG, PNG, GIF, upload up to 20 at a time',
    '900024': 'The page cannot be restored after deletion',
    '900025': 'Insist on deletion',
    '900026': 'Decided later',
    '900027': 'Keep at least one page in the scene',
    '900028': 'Got it',
    '900029': 'Page saving failed', '900030': '数字大小',
    '910000': 'Number color',
    '910001': 'Clock color',
    '910002': 'Minute color',
    '910003': 'Second color',
    '910004': 'Time scale color',
    '910005': 'Border 1 color',
    '910006': 'Border 2 color',
    '910007': 'Border 3 color',
    '910008': 'Time scale type',
    '910009': 'Circular',
    '910010': 'Triangle',
    '910011': 'Strip',
    '910012': 'Text color',
    '910013': 'English',
    '910014': 'Char',
    '910015': 'Split style',
    '910016': 'My',
    '910017': 'Program setting',
    '910018': 'Material list',
    '910019': 'Recently used',
    '910020': 'Recycle bin',
    '910021': 'Local upload',
    '910022': 'My videos',
    '910023': 'My pictures',
    '910024': 'My music',
    '910025': 'Import text',
    '910026': 'Local import',
    '910027': 'Material import',
    '910028': 'Weather',
    '910029': 'Temperature',
    '910030': 'Wind power',
    '910031': 'Air',
    '910032': 'Ultraviolet rays',
    '910033': 'Current temperature',
    '910034': 'Humidity',
    '910035': 'Weather location',
    '910036': 'Update time',
    '910037': 'Hour',
    '910038': 'Style',
    '910039': 'Show contents',
    '910040': 'Playback times',
    '910041': 'Start time',
    '910042': 'Residence time',
    '910043': 'Recently uploaded',
    '910044': 'Use now',
    '910045': 'Custom',
    '910046': 'Timing type',
    '910047': 'Timing end',
    '910048': 'Timing start',
    '910049': 'Count down',
    '910050': 'Positive timing',
    '910051': 'Day',
    '910052': 'Hour',
    '910053': 'Branch',
    '910054': 'Second',
    '910055': 'Display accuracy',
    '910056': 'Text description',
    '910057': 'Display content after deadline',
    '910058': 'Show before start',
    '910059': 'Here we go',
    '910060': 'Not yet',
    '910061': 'Multiple selection operation',
    '910062': '',
    '910063': '',
    '910064': '',
    '910065': '',
    '910066': '',
    '910067': '',
    '910068': 'End time',
    '910069': '',
    '910070': '',
    '910071': '当前储存空间不足20KB，请充值后上传！！！',
    '910072': '文件名称限制在50个字符内',
    '910073': '视频文件大小限制在',
    '910074': '图片文件大小限制在',
    '910075': '文件个数限制在',
    '910076': '当前所选文件大于 剩余储存量，请重新选择或充值后上传！！！',
    '910077': '内',
    '910078': 'Upload failed',
    '910079': 'Upload succeeded',
    '910080': 'Click to return to the program list',
    '910081': '',
    '910082': 'Do you want to save and close this page?',
    '910083': 'Absolute time',
    '910084': 'Relative time',
    '910085': 'Display after expiration',
    '910086': 'Display after timing',
    '910087': 'Time to',
    '910088': 'Display style',
    '910089': 'Display effect',
    '910090': '',
}
