<template>
    <div class="content-color2" v-if="!colorsShow" style="width: 160px; height: 100%; position: relative; display: inline-block; margin-right: 8px;" :style="{ width: w + 'px', height: h + 'px' }">
        <div class="lxc-input-color" v-if="!colorsShow" :style="{ width: w + 'px', height: h + 'px' }">
            <div x="{{x}}" y="{{y}}" id="demo-input" :style="{ backgroundColor: selectColor, width: w - 8, height: h - 8 }" @click="openChangeColor" select-color="selectColor" notify="{{notify}}" v-xwy-colorpicker="colorOptions" @change="change" direction="{{direction}}" colorpicker-with-input="true" is-toolbar-color-picker="idName" istoolbarcolor="{{idName}}" is-convert-transparent="{{isConvertTransparent}}"></div>
        </div>
    </div>
    <div class="content-color2" v-if="colorsShow" style="width: 160px; height: 100%; position: relative; display: inline-block;" :style="{ width: w + 'px', height: h + 'px' }">
        <div class="lxc-input-color">
            <div x="{{x}}" y="{{y}}" id="demo-input" :style="{ backgroundColor: selectColor, width: w - 8, height: h - 8 }" @click="openChangeColor" select-color="selectColor" notify="{{notify}}" v-xwy-colorpicker="colorOptions" @change="change" direction="{{direction}}" colorpicker-with-input="true" is-toolbar-color-picker="idName" istoolbarcolor="{{idName}}" is-convert-transparent="{{isConvertTransparent}}"></div>
        </div>
        <ul class="colors" v-if="colorsShow && colors.length > 1">
            <li class="color" v-for="(color, index) in colors" :key="index" :style="{ backgroundColor: color }" @click="setColor(color)"></li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        selectColor: {
            type: String
        },
        colorsShow: {
            type: Boolean, default: true
        },
        x: { type: Number, default: 0 },
        y: { type: Number, default: 0 },
        w: { type: Number, default: 168 },
        h: { type: Number, default: 0 },
    },
    emits: ['update:selectColor'],
    data() {
        var _this = this
        return {
            colorOptions: {
                value: this.selectColor,
                theme: "light",
                size: 'small',
                change(color) {
                    _this.$emit('update:selectColor', color)
                }
            },
            colorMode: null,
            direction: null,
            theme: '',
            notify: '',
            idName: '',
            isConvertTransparent: ''
        }
    },
    created() {
        this.colors = [hexToRgbA('#ffffff'), hexToRgbA('#ff5448'), hexToRgbA('#f2a653'), hexToRgbA('#ffca28'), hexToRgbA('#18cfa1'), hexToRgbA('#1261FF'), hexToRgbA('#4d8ff3'), hexToRgbA('#7171ef'), hexToRgbA('#4f5975'), hexToRgbA('#000000')];

        function hexToRgbA(hex) {
            var c = void 0;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('');

                if (c.length === 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }

                c = '0x' + c.join('');

                return 'rgba(' + [c >> 16 & 255, c >> 8 & 255, c & 255].join(',') + ',1)';
            }

            throw new Error('Bad Hex');
        }
    },
    methods: {
        setColor(color) {
            this.$emit('update:selectColor', this.hexify(color))
            //this.selectColor = color
        },
        hexify(color) {
            var values = color
                .replace(/rgba?\(/, '')
                .replace(/\)/, '')
                .replace(/[\s+]/g, '')
                .split(',');
            var a = parseFloat(values[3] || 1),
                r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
                g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
                b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
            return "#" +
                ("0" + r.toString(16)).slice(-2) +
                ("0" + g.toString(16)).slice(-2) +
                ("0" + b.toString(16)).slice(-2);
        }
    }
}
</script>
